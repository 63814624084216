import React, { useState, useEffect } from 'react';
import {
  Button,
  Cascader,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  TreeSelect,
  FormProps,
} from 'antd';
import { APIExpert, getExperts } from '../code/utils';
import { config } from '../code/vars';


const { Option } = Select;


async function updateExpert(id:number, newSettings:APIExpert){
  const response = await fetch(
    config.api_base + `/qa/experts/update/${id}`, 
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSettings),
    }
  );
  
  return response.status == 200;
}


export default function ExpertSettings(){
    const [expert, setExpert] = useState<APIExpert|undefined>(undefined)
    const [experts, setExperts] = useState<{[key:number]:APIExpert}>([])
    const [form] = Form.useForm();

    async function fetchExperts(){
      const tmpExperts = await getExperts();
      let newExperts: {[key:number]:APIExpert} = {}
      for(const e of tmpExperts){
        newExperts[e.id] = e
      }
      setExperts(newExperts);
    }

    useEffect(() => {
      

      fetchExperts();

    }, []);

    useEffect(() => {
      expert && form.setFieldsValue(expert);
    }, [expert]);

      
      const onFinish: FormProps<APIExpert>['onFinish'] = async (values) => {
        console.log('Success:', values);
        if(expert){
          const success = await updateExpert(expert.id, values);
          if(success){
            alert('Settings updated successfully')
            fetchExperts();
          }else{
            alert('Error saving settings')
          }
        }
      };
      
      const onFinishFailed: FormProps<APIExpert>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };



return(
<Flex justify='center' align='center' vertical style={{width:'100%', padding:'15%', paddingTop:'7%'}}>
    <Flex justify='center' align='center' gap='middle'>
        <h3>Change settings for:</h3> 
        <Select placeholder='Select' style={{minWidth:200}} value={expert?.id} onChange={(v)=>setExpert(experts[v])}>
          {Object.values(experts).map((e)=>{
            return <Option key={e.id} value={e.id}>{e.friendly_name}</Option>
          })}
        </Select>
    </Flex>
    {expert && <Form 
        // initialValues={expert}
        variant="filled" 
        style={{width:'100%' }} 
        labelWrap 
        labelCol={{span: 24}}
        wrapperCol={{span:24}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
    >

    <Form.Item
    label="Base Prompt"
    name="base_prompt"
    rules={[{ required: true, message: 'A base prompt must be provided' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Describe the speaking style and tone that should be used"
    name="speaking_style"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Important background information that should always be considered"
    name="background"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Topics that are allowed to be discussed"
    name="only_discuss"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Topics that should never be discussed"
    name="avoid"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Rules that should always be followed"
    name="rules"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>



    <Form.Item wrapperCol={{ offset: 22, span:2 }}>
    <Button type="primary" htmlType="submit">
        Save
    </Button>
    </Form.Item>
    </Form>}
</Flex>

)};