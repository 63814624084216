import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, Input, Typography } from 'antd';
import { config } from '../code/vars';

const { Text } = Typography;

const { TextArea } = Input;

interface PromptModalProps{
  prompt: string,
  setPrompt: Function
}


const PromptModal: React.FC<React.PropsWithChildren<PromptModalProps>> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <span onClick={showModal}>
        {props.children}
      </span>
      <Modal 
        title="Edit Prompt" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleOk}
        footer={[<Button
            key="ok"
            type="primary"
            onClick={handleOk}
          >
            OK
          </Button>]}
        // onCancel={handleCancel}
      >
            <Input.TextArea onChange={(e)=>props.setPrompt(e.target.value)} value={props.prompt} autoSize/>
      </Modal>
    </>
  );
};

export default PromptModal;