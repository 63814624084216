import React from 'react';
import { Flex, Form, Input, Select, Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { experts } from '../code/utils';
import { Navigate, useNavigate } from 'react-router-dom';
import type { FormProps } from 'antd';
import { config } from '../code/vars';

// enum FieldType {
//     string = "string",
//     int = "int",
//     float = "float",
//     select = "select",
//     multiselect = "multiselect",
//     bool = "bool",
//     text = "text"
// }

type FieldType = "string" | "int" | "float" | "select" | "multiselect" | "bool" | "text"

interface SurveyQuestionConf {
    question : string,
    type: FieldType,
    is_meta?: boolean,
    options? : Array<string>,
    user_generated?: boolean,
    max_length?: number
}

interface SurveyQuestionProps {
    conf: SurveyQuestionConf
}

const SurveyQuestion: React.FC<SurveyQuestionProps> = (props) => {
    function inputSwitch(conf: SurveyQuestionConf) {
        switch(conf.type) {
            case "string":
                return <Input />;
            case "int":
                return <Input type='number'/>;
            case "select":
                return <Select options={conf.options?.map(x => {return {value:x, label:x}}) || []}/>
            case "text":
                return <Input.TextArea />
            default:
                return <Input />;
        }
      }

    return (
        
        <div>
            <Title level={5}>{props.conf.question}</Title>
            <Form.Item
            name={props.conf.question}
        >
            {
                inputSwitch(props.conf)
            }
        </Form.Item>

        </div>
        
    )
}

const Survey: React.FC = () => {

    const navigate = useNavigate();
    let form_config : Array<SurveyQuestionConf> = [
        {
            "question": "First Name",
            "type": "string"
        },
        {
            "question": "Last Name",
            "type": "string"
        },
        {
            "question": "What are the most important lessons you've learned in your career so far?",
            "type": "text"
        }
        ,
        {
            "question": "Looking back, what do you think have been the keys to your success and effectiveness as a leader?",
            "type": "text"
        }
        ,
        {
            "question": "What is your approach or philosophy when it comes to developing talent and building high-performing teams?",
            "type": "text"
        }
        ,
        {
            "question": "How do you typically make difficult decisions, especially when there are pros and cons to various options?",
            "type": "text"
        }
        ,
        {
            "question": "What frameworks or principles guide you?",
            "type": "text"
        }
        ,
        {
            "question": "What worries you the most when you look at the current state and future of the pharmaceutical industry?",
            "type": "text"
        }
        ,
        {
            "question": "What keeps you up at night?",
            "type": "text"
        }
        ,
        {
            "question": "If you could go back and give your younger self some career advice, what would it be?",
            "type": "text"
        }
        ,
        {
            "question": "What do you know now that you wish you knew earlier in your career?",
            "type": "text"
        },
        {
            "question": "When you reflect on your career, what accomplishments are you most proud of and why? What impact did they have on the business, the team, or the patients?",
            "type": "text"
        },
        {
            "question": "In leading and motivating teams, how do you balance driving for results with empathy, compassion and understanding for your people?",
            "type": "text"
        },
        {
            "question": "How do you tailor your style to what different individuals need?",
            "type": "text"
        },
        {
            "question": "What have been some of your most valuable career experiences in terms of shaping who you are as a leader and how you operate?",
            "type": "text"
        },
        {
            "question": "Were there any crucible moments that really tested you?",
            "type": "text"
        },
        {
            "question": "How do you stay on top of trends and developments in the industry?",
            "type": "text"
        },
        {
            "question": "What sources of information and insight do you rely on to inform your thinking and decision-making?",
            "type": "text"
        },
        {
            "question": "As a leader, how do you foster a culture of innovation, calculated risk-taking and continuous improvement? What does that look like in practice?",
            "type": "text"
        },
        {
            "question": "What advice would you give to someone early in their career who aspires to a leadership role like yours someday? What capabilities and qualities should they focus on developing?",
            "type": "text"
        },
        {
            "question": "In your experience, what are the most common mistakes or pitfalls that leaders make? How have you learned to avoid them?",
            "type": "text"
        },
        {
            "question": "How do you approach building relationships and influencing stakeholders, both inside and outside the organization? Can you share any examples of how you've done this effectively?",
            "type": "text"
        },
        {
            "question": "What role does mentorship play in your own development and in how you develop others?",
            "type": "text"
        },
        {
            "question": "Who have been some of your most impactful mentors and what made those relationships so valuable?",
            "type": "text"
        },
        {
            "question": "How do you define and measure success, both for yourself and for your team/organization? How has your view on this evolved over time?",
            "type": "text"
        },
        {
            "question": "In an industry as complex and heavily regulated as pharma, how do you drive simplification and ensure focus on the things that matter most?",
            "type": "text"
        },
        {
            "question": "How do you communicate priorities effectively?",
            "type": "text"
        },
        {
            "question": "What are you most excited about as you look to the future?",
            "type": "text"
        },
        {
            "question": "What innovations or industry shifts do you believe will create the greatest opportunities in the years ahead?",
            "type": "text"
        }            
               
    ]

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        console.log('Success:', values);

        const response = await fetch(
            config.api_base + '/submit_form', 
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            }
          );

        if(response.status == 200){
            alert('responses saved')
        }else{
            alert('error saving responses')
        }
    };

    return(
        
        <Flex style={{width:'100%'}} justify='center'>
            <Form 
                style={{width: '50%'}}
                onFinish={onFinish}
            >
                {
                    form_config.map((conf, idx)=>{return <SurveyQuestion key={'question_' + idx} conf={conf}/>})
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
        
    );
    
  
    };

export default Survey;