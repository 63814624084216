const config_local = {
    company_name: 'askme-gpt',
    api_base: 'http://localhost:8000/api'
};

const config_remote = {
    company_name: 'askme-gpt',
    api_base: '/api'
};

const config = config_remote;

export { config };