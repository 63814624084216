import React, { useState, useEffect } from 'react';
import {
  Button,
  Cascader,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  TreeSelect,
  FormProps,
} from 'antd';
import { APIExpert, APIExpertInsert, getExperts } from '../code/utils';
import { config } from '../code/vars';
import { useNavigate } from 'react-router-dom';


const { Option } = Select;


async function createExpert(newExpert:APIExpertInsert){
  const response = await fetch(
    config.api_base + `/qa/experts/create`, 
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newExpert),
    }
  );
  
  return response.status == 200;
}


export default function CreateExpert(){
    const [expert, setExpert] = useState<APIExpert|undefined>(undefined)
    const [experts, setExperts] = useState<{[key:number]:APIExpert}>([])
    const [form] = Form.useForm();
    const navigate = useNavigate();




      
      const onFinish: FormProps<APIExpert>['onFinish'] = async (values) => {
        console.log('Success:', values);

          const success = await createExpert(values);
          if(success){
            alert('Expert created successfully');
            navigate('/');
          }else{
            alert('Error saving expert');
          }

      };
      
      const onFinishFailed: FormProps<APIExpert>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };



return(
<Flex justify='center' align='center' vertical style={{width:'100%', padding:'15%', paddingTop:'7%'}}>
    <Form 
        // initialValues={expert}
        variant="filled" 
        style={{width:'100%' }} 
        labelWrap 
        labelCol={{span: 24}}
        wrapperCol={{span:24}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
    >

    <Form.Item
    label='Name Your Expert'
    name='friendly_name'
    >
        <Input placeholder='Expert Name' style={{minWidth:200}}/>
    </Form.Item>

    <Form.Item
    label="Base Prompt"
    name="base_prompt"
    rules={[{ required: true, message: 'A base prompt must be provided' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Describe the speaking style and tone that should be used"
    name="speaking_style"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Important background information that should always be considered"
    name="background"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Topics that are allowed to be discussed"
    name="only_discuss"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Topics that should never be discussed"
    name="avoid"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>

    <Form.Item
    label="Rules that should always be followed"
    name="rules"
    rules={[{ required: false, message: 'Please input!' }]}
    >
    <Input.TextArea autoSize/>
    </Form.Item>



    <Form.Item wrapperCol={{ offset: 22, span:2 }}>
    <Button type="primary" htmlType="submit">
        Save
    </Button>
    </Form.Item>
    </Form>
</Flex>

)};