import React, { ReactElement, useState, useEffect } from 'react';
import { Button, Card, Flex, Input, Select, Tag, Typography } from 'antd';
import { ArrowRightOutlined, BulbOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { TypeAnimation } from 'react-type-animation';
import { ReactTyped } from 'react-typed';
import { Expert } from '../code/utils';
import { ChatMessageProps } from './ChatMessage';
import PromptModal from './PromptEditor';

const { Title } = Typography
const { Text } = Typography

export interface AutoSizeType {
    minRows?: number;
    maxRows?: number;
}

interface ChatBoxProps {
    experts?: Map<string, Expert>,
    onExpertChange?: (value: any, option: Expert | Expert[]) => void,
    expert?: Expert
    autoSize: boolean | AutoSizeType | undefined
    onMessage: Function,
    conversationStarted: boolean,
    errors:Array<string>
}



const ChatBox: React.FC<ChatBoxProps> = (props) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [curText, setCurText] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('')

    // function onExpertChange(key:string){
    //     setExpert(experts.get(key));
    // }

    const expertOpts = props.experts ? Array.from(props.experts.values()) : [];

    useEffect(()=>{
        if(props.expert){
            setPrompt(props.expert.prompt);
        }
    },[props.expert])

    function handleSubmit(){
        console.log(prompt)
        props.onMessage(curText, prompt);
        setCurText('');
    }

    // function setPrompt(newPrompt:string){
    //     if(props.expert){
    //         props.expert.prompt = newPrompt;
    //     }
    // }



  return (
    <Flex style={{width: '100%'}} justify='center' align='center' vertical>
        <div style={{width:'60%'}}>
            { props.errors.map((e)=>{return <Text key={e} type="danger">{'*' + e}</Text>})}
        </div>
        <Card 
          style={{width: '60%', borderColor:focused?'#1677FF':'#D9D9D9'}}
          styles={{body:{padding:0}, header:{padding:0, border:'none'}}}
          title={
            <Input.TextArea 
              onBlur={()=>setFocused(!focused)}
              onFocus={()=>setFocused(!focused)}
              onChange={(e)=>{setCurText(e.target.value)}}
              onPressEnter={handleSubmit}
              value={curText}
              placeholder={'Ask' + (props.expert ? ' ' + props.expert.friendlyName : '') + ' a question...'}
              style={{width: '100%', margin:0, border:'none'}} 
              autoSize={props.autoSize}
            />
          }
        >
          <Flex justify='space-between' align='flex-end' style={{padding:5}}>
          <div>
                {props.experts && props.onExpertChange ? <Select 
                onChange={props.onExpertChange}
                showSearch
                placeholder='Select an expert'
                size='small'
                options={expertOpts}
                /> : null}
                <PromptModal prompt={prompt} setPrompt={setPrompt}>
                    <SettingOutlined style={{marginLeft:10}}/>
                </PromptModal>
            </div>
                
            <Button onClick={handleSubmit} shape='circle' type='default' icon={<ArrowRightOutlined/>} />
            
          </Flex>
        </Card>


        {props.expert && !props.conversationStarted? 
          <Flex wrap="wrap" gap="small" align='center' justify='center' style={{margin:5, width:'60%'}}>
            <div><BulbOutlined/>{'Conversation starters'}</div>
            {props.expert.starters.map((x,i)=>{return <Tag onClick={()=>{props.onMessage(x.label)}} icon={x.icon} style={{cursor:'pointer',backgroundColor:'white'}} key={x.label+i.toString()}>{x.label}</Tag>})}
          </Flex> : null
        }
    </Flex>

  );
};

// export default ChatMessage;
export { type ChatBoxProps, ChatBox };