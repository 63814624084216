

import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppLayout from './components/AppLayout'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider, theme } from 'antd';

import Home from './pages/Home';
import Chat from './pages/Chat';
import Discover from './pages/Discover';
import Survey from './pages/Survey';
import ExpertSettings from './pages/ExpertSettings';
import CreateExpert from './pages/CreateExpert';



function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBg: '#f5f5f5',
            subMenuItemBg: '#f5f5f5',
            lineType: 'undefined'
          },
          Layout: {
            siderBg: "#f5f5f5",
            footerBg: "#f5f5f5",
            triggerBg: "#f5f5f5",
            triggerColor: "#000000E0",
            footerPadding:undefined
          }
        },

      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout/>}>
            <Route index element={<Chat/>} />
            <Route path="chat/" element={<Chat/>} />
            <Route path="create/" element={<CreateExpert/>} />
            <Route path="chat/:expert" element={<Chat/>} />
            <Route path="settings/" element={<ExpertSettings/>} />
            <Route path="discover" element={<Discover/>} />
            <Route path="survey" element={<Survey/>} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
