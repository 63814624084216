import React from 'react';
import { Card, Flex, List } from 'antd';
import Title from 'antd/es/typography/Title';
import { experts } from '../code/utils';
import { Navigate, useNavigate } from 'react-router-dom';


const Discover: React.FC = () => {

    const navigate = useNavigate();

    return(<Flex style={{width:'100%', height:'100%'}} vertical align='center' justify='center'>
        <Title style={{color:'#003eb3'}} level={1}>Find an expert</Title>
        <Flex justify='space-around' gap='large' wrap='wrap' style={{width:'60%'}}>
            {Array.from(experts.values()).map((x)=>{return <Card onClick={()=>{navigate('/chat/' + x.value)}} style={{width:'100%',cursor:'pointer'}} styles={{title:{color:'#1677FF'}}} title={x.label}>{x.summary}</Card>})}
        </Flex>
        {/* // <List
        //     style={{width: '60%'}}
        //     grid={{
        //     gutter: 16,
        //     xs: 1,
        //     sm: 2,
        //     md: 4,
        //     lg: 4,
        //     xl: 6,
        //     xxl: 3,
        //     }}
        //     dataSource={Array.from(experts.values())}
        //     renderItem={(item) => (
        //     <List.Item style={{height:'100%'}}>
                
        //     </List.Item>
        //     )}
        // /> */}
    </Flex>);
    
  
    };

export default Discover;